import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoPerson, IoHelpCircle, IoHome, IoLogOut, IoImages, IoVolumeMedium } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { logOut, reset } from "../features/authSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { admins } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(logOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div>
      <aside className="menu pl-2 has-shadow">
        <p className="menu-label">General</p>
        <ul className="menu-list">
          <li>
            <NavLink to={"/dashboard"}>
              <IoHome />
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to={"/admins"}>
              <IoPerson />
              Admins
            </NavLink>
          </li>
        </ul>
        <p className="menu-label">Administration</p>
        <ul className="menu-list">
          <li>
            <NavLink to={"/pesertas"}>
              <IoPerson />
              Peserta
            </NavLink>
          </li>
          <li>
            <NavLink to={"/pembicaras"}>
              <IoVolumeMedium />
              Pembicara
            </NavLink>
          </li>
          <li>
            <NavLink to={"/gallerys"}>
              <IoImages />
              Gallery
            </NavLink>
          </li>
          <li>
            <NavLink to={"/faqs"}>
              <IoHelpCircle />
              Faqs
            </NavLink>
          </li>
        </ul>
        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          <li>
            <button onClick={logout} className="button is-white">
              <IoLogOut />
              Log Out
            </button>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
