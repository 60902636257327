import React from "react";
import { useSelector } from "react-redux";

const Welcome = () => {
  const { admins } = useSelector((state) => state.auth);

  return (
    <div>
      <h1 className="title has-text-centered">Dashboard</h1>
      <h2 className="subtitle has-text-centered">
        Welcome back <strong>{admins && admins.username}</strong>
      </h2>
    </div>
  );
};

export default Welcome;
