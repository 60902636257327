import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditAdmin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordBaru, setPasswordBaru] = useState("");
  const [confirmPasswordBaru, setConfirmPasswordBaru] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getAdminsById = async () => {
      try {
        const response = await axios.get(
          `https://api.vandr.online/admins/${id}`
        );
        setUsername(response.data.username);
        setPassword(response.data.password);
        setConfirmPasswordBaru(response.data.confirmPassword);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getAdminsById();
  }, [id]);

  const updateAdmins = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://api.vandr.online/admins/${id}`, {
        username: username,
        password: password,
        passwordBaru: passwordBaru,
        confirmPasswordBaru: confirmPasswordBaru,
      });
      navigate("/admins");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title has-text-centered">Admins</h1>
      <h2 className="subtitle has-text-centered">Update Admin</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateAdmins}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Username</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Password Lama</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Password Baru</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    value={passwordBaru}
                    onChange={(e) => setPasswordBaru(e.target.value)}
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Confirm Password Baru</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    value={confirmPasswordBaru}
                    onChange={(e) => setConfirmPasswordBaru(e.target.value)}
                    placeholder="Confirm Password"
                  />
                </div>
              </div>
              <div className="field ">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditAdmin;
