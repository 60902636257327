import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Pesertalist = () => {
  const [pesertas, setPesertas] = useState([]);

  useEffect(() => {
    getPesertas();
  }, []);

  const getPesertas = async () => {
    const response = await axios.get("https://api.vandr.online/pesertas");
    setPesertas(response.data);
  };

  const deletePeserta = async (pesertaID) => {
    await axios.delete(`https://api.vandr.online/pesertas/${pesertaID}`);
    getPesertas();
  };

  return (
    <div>
      <h1 className="title has-text-centered">Peserta</h1>
      <h2 className="subtitle has-text-centered">List of Peserta</h2>
      <Link to={"/pesertas/add"} className="button is-primary mb-2">
        Add Peserta
      </Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nama</th>
            <th>Jenis Kelamin</th>
            <th>Status</th>
            <th>No Identitas</th>
            <th>Email</th>
            <th>WhatsApp</th>
            <th>Alamat</th>
            <th>Status Pembayaran</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {pesertas.map((peserta, index) => (
            <tr key={peserta.uuid}>
              <td>{index + 1}</td>
              <td>{peserta.nama}</td>
              <td>{peserta.jenis_kelamin}</td>
              <td>{peserta.status}</td>
              <td>{peserta.no_identitas}</td>
              <td>{peserta.email}</td>
              <td>{peserta.whatsapp}</td>
              <td>{peserta.alamat}</td>
              <td>{peserta.status_pembayaran}</td>
              <td>
                <Link
                  to={`/pesertas/edit/${peserta.uuid}`}
                  className="button is-small is-info"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deletePeserta(peserta.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Pesertalist;
