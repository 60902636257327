import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const GalleryList = () => {
  const [gallerys, setGallerys] = useState([]);

  useEffect(() => {
    getGallerys();
  }, []);

  const getGallerys = async () => {
    const response = await axios.get("https://api.vandr.online/gallerys");
    setGallerys(response.data);
  };

  const deleteGallery = async (galleryID) => {
    await axios.delete(`https://api.vandr.online/gallerys/${galleryID}`);
    getGallerys();
  };

  return (
    <div className="container mt-5">
      <h1 className="title has-text-centered">Gallery</h1>
      <h2 className="subtitle has-text-centered">List of Gallery</h2>
      <Link to={"/gallerys/add"} className="button is-primary mb-2">
        Add Gallery
      </Link>
      <div className="columns is-multiline">
        {gallerys.map((gallery) => (
          <div key={gallery.id} className="column is-one-quarter">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src={gallery.url} alt={gallery.alt} />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">{gallery.title}</p>
                    <p className="subtitle is-6">{gallery.deskripsi}</p>
                  </div>
                </div>
              </div>

              <footer className="card-footer">
                <Link to={`edit/${gallery.uuid}`} className="card-footer-item">
                  Edit
                </Link>
                <Link
                  onClick={() => deleteGallery(gallery.uuid)}
                  className="card-footer-item"
                >
                  Delete
                </Link>
                {/* <a
                  onClick={() => deleteGallery(gallery.uuid)}
                  className="card-footer-item"
                >
                  Delete
                </a> */}
              </footer>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GalleryList;
