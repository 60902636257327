import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Login from "./components/login";
import Admins from "./pages/admins";
import Faqs from "./pages/faqs";
import Pembicaras from "./pages/pembicaras";
import Pesertas from "./pages/pesertas";
import Gallerys from "./pages/gallerys";
import AddAdmin from "./pages/addAdmin";
import AddFaq from "./pages/addFaq";
import AddPeserta from "./pages/addPeserta";
import AddPembicara from "./pages/addPembicara";
import AddGallery from "./pages/addGallery";
import EditAdmin from "./pages/editAdmin";
import EditFaq from "./pages/editFaq";
import EditPeserta from "./pages/editPeserta";
import EditPembicara from "./pages/editPembicara";
import EditGallery from "./pages/editGallery";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admins" element={<Admins />} />
          <Route path="/admins/add" element={<AddAdmin />} />
          <Route path="/admins/edit/:id" element={<EditAdmin />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/faqs/add" element={<AddFaq />} />
          <Route path="/faqs/edit/:id" element={<EditFaq />} />
          <Route path="/pesertas" element={<Pesertas />} />
          <Route path="/pesertas/add" element={<AddPeserta />} />
          <Route path="/pesertas/edit/:id" element={<EditPeserta />} />
          <Route path="/pembicaras" element={<Pembicaras />} />
          <Route path="/pembicaras/add" element={<AddPembicara />} />
          <Route path="/pembicaras/edit/:id" element={<EditPembicara />} />
          <Route path="/gallerys" element={<Gallerys />} />
          <Route path="/gallerys/add" element={<AddGallery />} />
          <Route path="/gallerys/edit/:id" element={<EditGallery />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
