import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddFaq = () => {
  const [pertanyaan, setPertanyaan] = useState("");
  const [jawaban, setJawaban] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const addFaq = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://api.vandr.online/faqs", {
        pertanyaan: pertanyaan,
        jawaban: jawaban,
      });
      navigate("/faqs");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title has-text-centered">Faqs</h1>
      <h2 className="subtitle has-text-centered">Add new Faqs</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={addFaq}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Pertanyaan</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={pertanyaan}
                    onChange={(e) => setPertanyaan(e.target.value)}
                    placeholder="Pertanyaan"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Jawaban</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={jawaban}
                    onChange={(e) => setJawaban(e.target.value)}
                    placeholder="Jawaban"
                  />
                </div>
              </div>
              <div className="field ">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddFaq;
