import React from 'react'
import Layout from './layout'
import Pesertalist from '../components/pesertalist'

const pesertas = () => {
  return (
    <Layout>
        <Pesertalist/>
    </Layout>
  )
}

export default pesertas