import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    admins: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const LoginAdmin = createAsyncThunk("admin/LoginAdmin", async (admins, thunkAPI) => {
    try {
        const response = await axios.post('https://api.vandr.online/login', {
            username: admins.username,
            password: admins.password
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const getMe = createAsyncThunk("admin/getMe", async (_, thunkAPI) => {
    try {
        const response = await axios.get('https://api.vandr.online/me');
        return response.data;
    } catch (error) {
        if (error.response) {
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const logOut = createAsyncThunk("admin/logOUt", async () => {

    await axios.delete('https://api.vandr.online/logout');

});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(LoginAdmin.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(LoginAdmin.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.admins = action.payload
        })
        builder.addCase(LoginAdmin.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })

        //get admin login
        builder.addCase(getMe.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.admins = action.payload
        })
        builder.addCase(getMe.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    }
})

export const { reset } = authSlice.actions;
export default authSlice.reducer;
