import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddPeserta = () => {
  const [nama, setNama] = useState("");
  const [jenisKelamin, setJenisKelamin] = useState("");
  const [status, setStatus] = useState("");
  const [noIdentitas, setNoIdentitas] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [alamat, setAlamat] = useState("");
  const [statusPembayaran, setStatusPembayaran] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const addPeserta = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://api.vandr.online/pesertas", {
        nama: nama,
        jenis_kelamin: jenisKelamin,
        status: status,
        no_identitas: noIdentitas,
        email: email,
        whatsapp: whatsapp,
        alamat: alamat,
        status_pembayaran: statusPembayaran,
      });
      navigate("/pesertas");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title has-text-centered">Peserta</h1>
      <h2 className="subtitle has-text-centered">Add New Peserta</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={addPeserta}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Nama</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={nama}
                    onChange={(e) => setNama(e.target.value)}
                    placeholder="Nama"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Jenis Kelamin</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={jenisKelamin}
                      onChange={(e) => setJenisKelamin(e.target.value)}
                    >
                      <option value="">Pilih Jenis Kelamin</option>
                      <option value="laki-laki">Laki-laki</option>
                      <option value="perempuan">Wanita</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Status</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Pilih Status</option>
                      <option value="umum">Umum</option>
                      <option value="amikom">AMIKOM</option>
                      <option value="koma">KOMA</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">No Identitas</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={noIdentitas}
                    onChange={(e) => setNoIdentitas(e.target.value)}
                    placeholder="18.12.0961"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Whatsapp</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={whatsapp}
                    onChange={(e) => setWhatsapp(e.target.value)}
                    placeholder="Whatsapp"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Alamat</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={alamat}
                    onChange={(e) => setAlamat(e.target.value)}
                    placeholder="Alamat"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Status Pembayaran</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={statusPembayaran}
                      onChange={(e) => setStatusPembayaran(e.target.value)}
                    >
                      <option value="belum">Belum</option>
                      <option value="sudah">Sudah</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field ">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddPeserta;
