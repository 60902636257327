import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Faqlist = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    const response = await axios.get("https://api.vandr.online/faqs");
    setFaqs(response.data);
  };

  const deleteFaqs = async (faqID) => {
    await axios.delete(`https://api.vandr.online/faqs/${faqID}`);
    getFaqs();
  };

  return (
    <div>
      <h1 className="title has-text-centered">FAQ</h1>
      <h2 className="subtitle has-text-centered">List of Faqs</h2>
      <Link to={"/faqs/add"} className="button is-primary mb-2">
        Add FAQ
      </Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Pertanyaan</th>
            <th>Jawaban</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {faqs.map((faq, index) => (
            <tr key={faq.uuid}>
              <td>{index + 1}</td>
              <td>{faq.pertanyaan}</td>
              <td>{faq.jawaban}</td>
              <td>
                <Link
                  to={`/faqs/edit/${faq.uuid}`}
                  className="button is-small is-info"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteFaqs(faq.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Faqlist;
