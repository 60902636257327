import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Adminlist = () => {
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    getAdmins();
  }, []);

  const getAdmins = async () => {
    const response = await axios.get("https://api.vandr.online/admins");
    setAdmins(response.data);
  };

  const deleteAdmin = async (adminID) => {
    await axios.delete(`https://api.vandr.online/admins/${adminID}`);
    getAdmins();
  };

  return (
    <div>
      <h1 className="title has-text-centered">Admins</h1>
      <h2 className="subtitle has-text-centered">List of Admins</h2>
      <Link to={"/admins/add"} className="button is-primary mb-2">
        Add Admin
      </Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Username</th>
            <th>Password</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin, index) => (
            <tr key={admin.uuid}>
              <td>{index + 1}</td>
              <td>{admin.username}</td>
              <td>*****</td>
              <td>
                <Link
                  to={`/admins/edit/${admin.uuid}`}
                  className="button is-small is-info"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteAdmin(admin.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Adminlist;
