import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const PembicaraList = () => {
  const [pembicaras, setPembicaras] = useState([]);

  useEffect(() => {
    getPembicaras();
  }, []);

  const getPembicaras = async () => {
    const response = await axios.get("https://api.vandr.online/pembicaras");
    setPembicaras(response.data);
  };

  const deletePembicara = async (pembicaraID) => {
    await axios.delete(`https://api.vandr.online/pembicaras/${pembicaraID}`);
    getPembicaras();
  };

  return (
    <div className="container mt-5">
      <h1 className="title has-text-centered">Pembicara</h1>
      <h2 className="subtitle has-text-centered">List of Pembicara</h2>
      <Link to={"/pembicaras/add"} className="button is-primary mb-2">
        Add Pembicara
      </Link>
      <div className="columns is-multiline">
        {pembicaras.map((pembicara) => (
          <div key={pembicara.id} className="column is-one-quarter">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by3">
                  <img src={pembicara.url} alt="Image" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">{pembicara.nama}</p>
                    <p className="subtitle is-6">{pembicara.deskripsi}</p>
                  </div>
                </div>
              </div>

              <footer className="card-footer">
                <Link
                  to={`edit/${pembicara.uuid}`}
                  className="card-footer-item"
                >
                  Edit
                </Link>
                <a
                  onClick={() => deletePembicara(pembicara.uuid)}
                  className="card-footer-item"
                >
                  Delete
                </a>
              </footer>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PembicaraList;
